<template>
    <div class="column-group">
        <div class="row mx-0 align-items-center border-bottom py-3">
            <div class="col-auto f-18 text-general f-600">
                Grupos de geocerca
            </div>
            <el-tooltip placement="bottom" content="Crear grupo" effect="light" class="mr-2">
                <div v-if="$can('gestionar_geocercas')" class="btn-red ml-auto" @click="crearGrupo">
                    <i class="icon-plus" />
                </div>
            </el-tooltip>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 230px)">
            <div class="row mx-0 justify-center">
                <div v-for="(data,key) in geocercasCarpeta" :key="key" class="col-10 border br-8 py-2 mb-2 cr-pointer" @click="verGrupo(data.id)">
                    <p class="f-16 f-600 text-general">
                        {{ data.nombre }}
                    </p>
                    <div class="row mx-0 align-items-center mt-2">
                        <i class="icon-geocerca f-17 text-general" />
                        <el-tooltip placement="bottom" :content="data.descripcion" effect="light">
                            <div class="col-auto pl-0 f-16 text-general">
                                {{ data.cant_geocercas }} Geocercas
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div v-if="geocercasCarpeta.length == 0" class="row mx-0 justify-center mt-4">
                <div class="col-10 text-center text-general mb-2">
                    Aun no se han creado grupos <br /> de geocercas
                </div>
                <div class="col-10">
                    <div class="btn-general" @click="crearGrupo">
                        Crear grupo de geocercas
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalCrearGrupo" titulo="Crear grupo de geocerca" no-aceptar adicional="Guardar" @adicional="guardarGeocerca">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-10 my-3">
                        <small class="pl-3 text-general">
                            Nombre del grupo
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                            <el-input v-model="grupo" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-10">
                        <small class="pl-3 text-general">
                            Descripción
                        </small>
                        <el-input v-model="descripcion" placeholder="Descripción" maxlength="350" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </div>
</template>

<script>
import Geocerca from '~/services/geocerca/geocerca'
export default {
    data(){
        return {
            grupo: '',
            descripcion: '',
            geocercasCarpeta: []
        }
    },
    mounted(){
        this.getGeocercasCarpetas()
    },
    methods: {
        verGrupo(id){
            this.$router.push({name: 'admin.geocercas.grupos.ver', params: { idGrupo: id }})
        },
        crearGrupo(){
            this.$refs.modalCrearGrupo.toggle();
        },
        async getGeocercasCarpetas(){
            try {
                const {data} = await Geocerca.getGeocercasCarpetas()
                this.geocercasCarpeta = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async guardarGeocerca(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const payload = {
                    nombre: this.grupo,
                    descripcion: this.descripcion
                }
                await Geocerca.postGeocerca(payload)

                this.notificacion('','Geocerca guardada correctamente','success')
                this.$refs.modalCrearGrupo.toggle();
                this.getGeocercasCarpetas();
            } catch (e){
                this.error_catch(e)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.column-group{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
</style>
